import { useQuery } from "@apollo/react-hooks";
import { ALL_CLIENT_CONFIGS } from "graphql/queries";

export type CategoryColor = {
  color: string,
  colorBrightness: 'light' | 'dark',
  progressColor: string,
}

export type LanguageSetting = {
  label: string,
  value: string,
}

type ClientConfig = {
  loading: boolean,
  backgroundUrl: string
  cantCompleteAnnouncement: string
  clientLogo: string
  primaryColor: string
  fontColor: string
  iconColor: string
  welcomeIconUrl: string
  editorName: string
  showLocale: boolean,
  useExternalUsersForRoleAssignments?: boolean,
  disableCantCompleteNotes: boolean,
  enableNotes: boolean,
  showUserTemplateRoleAssignment: boolean,
  sendSafely?: {
    url: string,
    dropzoneId: string,
  }
  enableNewHome: boolean
  enableWelcomeModal: boolean
  categoryColors: CategoryColor[] | undefined
  availableLanguages: LanguageSetting[] | undefined
  disableRoleAssignmentEmail: boolean
}

const useClientConfig = () => {
  const { data, loading } = useQuery(ALL_CLIENT_CONFIGS)
  const configuration = data?.configuration[0]
  const flags = data?.clientConfig
  const settings = data?.clientSetting

  return {
    ...configuration,
    ...flags,
    ...settings,
    loading,
  } as ClientConfig
};

export default useClientConfig;
