import React, { useCallback, useState } from 'react'
import dayjs from 'dayjs';
import { useQuery } from '@apollo/react-hooks'
import { GET_TAXONOMY_TERM_HIERARCHY, GET_USERS } from 'graphql/queries'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Autocomplete, Checkbox, CircularProgress, debounce, FormControlLabel, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from 'styled-components';
import COLORS from 'constants/colors'
import { FETCH_POLICIES } from 'constants/api';
import MuiPhoneNumber from 'material-ui-phone-number'

const QuestionUpdateEmployee = ({
  questionId,
  options,
  onOptionSelected,
  comment,
}: {
  questionId?: string
  options: QuestionOptionType[]
  onOptionSelected?: (currentState: any) => void
  comment?: string
}) => {
  return <>
    <Title>{options[0].title}</Title>
    {options[0].taxonomyId && <TaxonomySelection
      taxonomyId={options[0].taxonomyId}
      onOptionSelected={onOptionSelected}
      questionId={questionId}
      options={options}
      comment={comment}
    />}
    {options[0].dateTypeId && <DateSelection
      onOptionSelected={onOptionSelected}
      questionId={questionId}
      options={options}
      comment={comment}
    />}
    {options[0].roleId && <RoleSelection
      onOptionSelected={onOptionSelected}
      questionId={questionId}
      options={options}
      comment={comment}
    />}
    {options[0].propertyName && options[0].propertyName != 'phone_number' && <PropertySelection
      onOptionSelected={onOptionSelected}
      questionId={questionId}
      comment={comment}
    />}
    {options[0].propertyName && options[0].propertyName == 'phone_number' && <PhoneNumberSelection
      onOptionSelected={onOptionSelected}
      questionId={questionId}
      comment={comment}
    />}
  </>
}

const TaxonomySelection = ({ taxonomyId, onOptionSelected, questionId, options, comment }) => {
  const { data: taxonomiesTermData, loading } = useQuery(GET_TAXONOMY_TERM_HIERARCHY, {
    variables: {
      taxonomyId: taxonomyId,
    },
    skip: !taxonomyId,
  })

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      onOptionSelected((currentState: any) => {
        currentState[questionId].comment = itemId;

        return {
          ...currentState
        };
      });
    }
  };

  const renderLevel = (taxonomyTermHierarchy) => {
    return taxonomyTermHierarchy.map((child) => {
      if (child.children?.length === 0) {
        return <TreeItem itemId={child.id}
          label={
            <FormControlLabel
              control={<Checkbox checked={child.id == comment} />}
              checked={child.id == options[0]?.taxonomyId}
              label={child.title}
              onChange={() => {
                onOptionSelected((currentState: any) => {
                  currentState[questionId].comment = child.id;

                  return {
                    ...currentState
                  };
                });
              }}
            />
          } />
      }

      return <TreeItem itemId={child.id} label={child.title} >
        {child.children?.length > 0 && renderLevel(child.children)}
      </TreeItem>
    })
  }
  const getAllNodeIds = (node) => {
    if (!node.children || node.children.length === 0) {
      return [node.id];
    }

    return [node.id, ...node.children.flatMap(getAllNodeIds)];
  };

  const allNodeIds = taxonomiesTermData
    ? getAllNodeIds(taxonomiesTermData?.taxonomyTermHierarchy[0])
    : [];

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div>
      <SimpleTreeView
        defaultExpandedItems={allNodeIds}
        onItemSelectionToggle={handleItemSelectionToggle}
      >
        {taxonomiesTermData && renderLevel(taxonomiesTermData?.taxonomyTermHierarchy)}
      </SimpleTreeView>
    </div>
  )
}


const DateSelection = ({ onOptionSelected, questionId, options, comment }) => {
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    onOptionSelected((currentState: any) => {
      currentState[questionId].comment = formattedDate;

      return {
        ...currentState,
      };
    });
  }

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      label={options[0].title}
      onChange={handleDateChange}
      defaultValue={comment ? dayjs(comment, 'YYYY-MM-DD') : null}
      sx={{
        backgroundColor: 'white',
        borderColor: COLORS.POWDER_BLUE,
        borderWidth: '2px',
        borderRadius: '6px',
      }}
    />
  </LocalizationProvider>
}

const RoleSelection = ({ onOptionSelected, questionId, options, comment }) => {
  const [searchText, setSearchText] = useState('');
  const debouncedSetSearchText = useCallback(
    debounce((newSearchText) => setSearchText(newSearchText), 500),
    [setSearchText]
  );

  const { data: usersData } = useQuery(GET_USERS, {
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
    variables: {
      query: searchText,
    },
  })

  const handleRoleChange = (event, newValue) => {
    onOptionSelected((currentState: any) => {
      currentState[questionId].comment = newValue?.id;

      return {
        ...currentState,
      };
    });
  }

  const handleInputChange = (event, newInputValue) => {
    debouncedSetSearchText(newInputValue);
  }

  return <Autocomplete
    options={usersData?.users.data || []}
    sx={{
      backgroundColor: 'white',
      borderColor: COLORS.POWDER_BLUE,
      borderWidth: '2px',
      borderRadius: '6px',
    }}
    getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
    onChange={handleRoleChange}
    onInputChange={handleInputChange}
    renderInput={(params) => <TextField
      {...params}
      defaultValue={searchText}
      label="Search for a user"
    />}
  />
}

const PropertySelection = ({ onOptionSelected, questionId, comment }) => {
  const handleTextChange = (event) => {
    onOptionSelected((currentState: any) => {
      currentState[questionId].comment = event.target.value;

      return {
        ...currentState,
      };
    });
  };

  return <TextField
    fullWidth
    defaultValue={comment}
    onChange={handleTextChange}
    sx={{
      backgroundColor: 'white',
      borderColor: COLORS.POWDER_BLUE,
      borderWidth: '2px',
      borderRadius: '6px',
    }}
  />
}

const PhoneNumberSelection = ({ onOptionSelected, questionId, comment }) => {
  const handlePhoneNumberChange = (phoneNumber: any) => {
    onOptionSelected((currentState: any) => {
      // Set value only when we have a number, otherwise we end up with `+` phone numbers (library issue)
      currentState[questionId].comment = phoneNumber.length > 5 ? phoneNumber : '';

      return {
        ...currentState,
      };
    });
  }

  return <MuiPhoneNumber
    defaultCountry={'us'}
    disableAreaCodes
    onChange={handlePhoneNumberChange}
    variant="outlined"
    fullWidth
    label="Phone Number"
    sx={{
      backgroundColor: 'white',
      borderColor: COLORS.POWDER_BLUE,
      borderWidth: '2px',
      borderRadius: '6px',
    }}
  />
}

const Title = styled.div`
  font-size: 16px;
  line-height: 27px;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 4px;
  margin-left: 4px;
  overflow-wrap: break-word;
`

export default QuestionUpdateEmployee

