import gql from 'graphql-tag'

import {
  FRAGMENT_TASK_FIELDS,
  FRAGMENT_TASK_VIRTUAL_FIELDS,
  FRAGMENT_LOCATION_HIERARCHY_FIELDS,
  FRAGMENT_SESSION_FIELDS,
  FRAGMENT_LOCATION_TEXT_FIELDS,
  TASK_COMPLETE_FIELDS,
  FRAGMENT_LOCATION_PHOTO_FIELDS,
  FRAGMENT_TASK_TEXT_FIELDS,
  FRAGMENT_TASK_PHOTO_FIELDS,
  FRAGMENT_PAGINATION_META_FIELDS,
  FRAGMENT_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS,
  FRAGMENT_EXTERNAL_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS,
  FRAGMENT_USER_WITH_ASSIGNEES_FIELDS,
} from 'graphql/fragments'
import { CACHE_KEYS, MODEL_TYPES } from 'constants/types'
import { FRAGMENT_USER_OWNER_FIELDS, UPNEXT_TASK_FIELDS } from './fragments'

const GET_CLIENT_CONFIG_STRING = `
  clientConfig {
    showLocale
    disableCantCompleteNotes
    enableNotes
    showUserTemplateRoleAssignment
    enableNewHome
    enableWelcomeModal
    useExternalUsersForRoleAssignments
    disableRoleAssignmentEmail
  }
`

const GET_SNIPPETS_QUERY_STRING = `
  snippets {
    id
    name
    value
    fallbackValue
    snippetType
  }
`

const LOCATIONS_QUERY_STRING = `
  locationHierarchiesNew(parentId: $parentId, locationTitle: $locationTitle, pagination: $pagination, showReporteesLocationsOnly: $showReporteesLocationsOnly) {
    data {
      ...LocationHierarchyFields
    }
    paginationMeta {
        ...PaginationMetaFields
      }
  }
`

const SESSIONS_QUERY_STRING = `
  sessions(startDate: $startDate) {
    ...SessionFields
  }
`

const LOCATION_TEXT_QUERY_STRING = `
  locationText(date: $date, locationHierarchyId: $locationHierarchyId) {
    ...LocationTextFields
  }
`
const LOCATION_PHOTOS_QUERY_STRING = `
  locationPhotos(locationHierarchyId: $locationHierarchyId, date: $date) {
    ...LocationPhotoFields
  }
`

const TASK_TEXT_QUERY_STRING = `
  taskText(date: $date, taskId: $taskId) {
    ...TaskTextFields
  }
`
const TASK_PHOTOS_QUERY_STRING = `
  taskPhotos(taskId: $taskId, date: $date) {
    ...TaskPhotoFields
  }
`

const TASK_VIRTUAL_QUERY_STRING = `
  tasksVirtual(startDate: $startDate, endDate: $endDate, locationHierarchyId: $locationHierarchyId) {
    ...TaskVirtualFields
  }
`

const TASK_COMPLETE_QUERY_STRING = `
  tasksComplete(date: $date, locationHierarchyId: $locationHierarchyId) {
    ${TASK_COMPLETE_FIELDS}
  }
`

export const LOCATION_TEXT_QUERY = gql`
  query LocationTextQuery(
    $locationHierarchyId: ID!,
    $date: ISO8601Date!
    )  {
    ${LOCATION_TEXT_QUERY_STRING}
  }
  ${FRAGMENT_LOCATION_TEXT_FIELDS}
`

export const LOCATION_PHOTOS_QUERY = gql`
  query LocationPhotosQuery(
    $locationHierarchyId: ID!,
    $date: ISO8601Date!
    ) {
    ${LOCATION_PHOTOS_QUERY_STRING}
  }
  ${FRAGMENT_LOCATION_PHOTO_FIELDS}
`

export const TASK_TEXT_QUERY = gql`
  query LocationTextQuery(
    $taskId: ID!,
    $date: ISO8601Date!
    )  {
    ${TASK_TEXT_QUERY_STRING}
  }
  ${FRAGMENT_TASK_TEXT_FIELDS}
`

export const TASK_PHOTOS_QUERY = gql`
  query TaskPhotosQuery(
    $taskId: ID!,
    $date: ISO8601Date!
    ) {
    ${TASK_PHOTOS_QUERY_STRING}
  }
  ${FRAGMENT_TASK_PHOTO_FIELDS}
`

export const TASKS_SCREEN_QUERY = gql`
  query TasksScreenQuery(
    $startDate: ISO8601Date!,
    $endDate: ISO8601Date!,
    $date: ISO8601Date!,
    $locationHierarchyId: ID!
    ) {
    ${TASK_VIRTUAL_QUERY_STRING}
    ${TASK_COMPLETE_QUERY_STRING}
    locationHierarchy(locationId: $locationHierarchyId) {
      rootNode {
        owner {
          ...UserOwnerFields
        }
      }
    }
  }
  ${FRAGMENT_TASK_VIRTUAL_FIELDS}
  ${FRAGMENT_USER_OWNER_FIELDS}
`

export const INITIAL_LOAD_ALL_QUERY = gql`
  query LoadInitial($startDate: ISO8601Date!, $endDate: ISO8601Date!, $limitToAssignmentsIfAvail: Boolean!, $locationTitle: String) {
    ${LOCATIONS_QUERY_STRING}
    ${SESSIONS_QUERY_STRING}
    ${TASK_VIRTUAL_QUERY_STRING}
  }
  ${FRAGMENT_LOCATION_HIERARCHY_FIELDS}
  ${FRAGMENT_SESSION_FIELDS}
  ${FRAGMENT_TASK_VIRTUAL_FIELDS}
`

export const LOCATION_SCREEN_QUERY = gql`
  query LocationScreenQuery(
    $parentId: ID
    $locationTitle: String
    $pagination: PaginationInput
    $showReporteesLocationsOnly: Boolean
  ) {
    ${LOCATIONS_QUERY_STRING}
  }
  ${FRAGMENT_LOCATION_HIERARCHY_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_CLIENT_CONFIG = gql`
  query ClientConfig {
    ${GET_CLIENT_CONFIG_STRING}
  }
`

export const GET_TASKS_VIRTUAL = gql`
  query TasksVirtualQuery($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    ${TASK_VIRTUAL_QUERY_STRING}
  }
  ${FRAGMENT_TASK_VIRTUAL_FIELDS}
`

export const GET_SESSIONS = gql`
  query SessionsQuery {
    ${SESSIONS_QUERY_STRING}
  }
  ${FRAGMENT_SESSION_FIELDS}
`

export const GET_TASKS_COMPLETE = gql`
  query TasksCompleteQuery($date: ISO8601Date!, $locationHierarchyId: ID!) {
    ${TASK_COMPLETE_QUERY_STRING}
  }
`

export const GET_DEPENDENT_TASK_COMPLETED_INFO = gql`
  query DependentTaskCompletedInfo($dependentTaskId: ID!) {
    dependentTaskCompletedInfo(dependentTaskId: $dependentTaskId) {
      ${TASK_COMPLETE_FIELDS}
      taskTitle
    }
  }
`

export const GET_CURRENT_USER = gql`
  query User {
    user {
      id
      firstName
      lastName
      isManager
      email
      clientId
      fullName
      workEmail
      personalEmail
      personalEmailSession
      homeAddress
      preferredLocale
      isAdmin
      jobTitle
      avatarUrl
      preferences {
        showCompletedWorkflow
      }
      taxonomyTerms {
        title
        id
        taxonomy {
          name
          slug
        }
      }
      startDate
      userDatesWithNames
      phoneNumber
      manager {
        firstName
        lastName
        fullName
        email
      }
      buddy {
        firstName
        lastName
        fullName
        email
      }
    }
  }
`

export const GET_CLIENT_PRODUCTS = gql`
  query ClientProductsQuery($locationHierarchyId: ID!) {
    clientProducts(locationHierarchyId: $locationHierarchyId) {
      id
      price
      parLevel
      clientProduct {
        id
        name
        image
      }
    }
  }
`

export const ERROR_DATA = gql`
  query ErrorData {
    errorData @client {
      hasError
      message
      status
    }
  }
`

export const APP_DATA = gql`
  query AppData {
    appData @client {
      taskDescriptionModalId
    }
  }
`

export const GET_PAGES = gql`
  query Pages($showAll: Boolean) {
    contentPages(showAll: $showAll) {
      id
      title
      iconUrl
    }
  }
`

export const GET_PAGE = gql`
  query Pages($pageId: ID!) {
    contentPage(pageId: $pageId) {
      id
      title
      sections {
        id
        sectionType
        hidden
        content
        pictureUrl
        icon1Url
        icon2Url
        icon3Url
        icon4Url
        icon5Url
        icon6Url
        icon7Url
        icon8Url
        icon9Url
        icon10Url
      }
    }
  }
`

export const UP_NEXT_TASKS_QUERY = gql`
  query UpNextTasks($locationIds: [ID!]!) {
    upNextTasks(locationIds: $locationIds) {
      pastDue {
        ${UPNEXT_TASK_FIELDS}
      }
      due {
        ${UPNEXT_TASK_FIELDS}
      }
    }
  }
`

export const UP_NEXT_TASKS_WITH_CALENDAR_QUERY = gql`
  query UpNextTasks($locationIds: [ID!]!) {
    upNextTasks(locationIds: $locationIds) {
      pastDue {
        ${UPNEXT_TASK_FIELDS}
      }
      due {
        ${UPNEXT_TASK_FIELDS}
      }
      calendar {
        ${UPNEXT_TASK_FIELDS}
      }
    }
  }
`

export const GET_SNIPPETS = gql`
  query Snippets {
    ${GET_SNIPPETS_QUERY_STRING}
  }
`

export const GET_USER_SNIPPET_VALUES = gql`
  query UserSnippetValues($userId: ID!) {
    userSnippetValues(userId: $userId)
  }
`

export const GET_RESOURCES_HIERARCHY = gql`
  query ResourcesHierarchy {
    resourcesHierarchy {
      id
      title
      position
      children {
        id
      }
      resources {
        id
        title
        url
        position
        favorite
        iconUrl
      }
    }
  }
`

export const GET_ONBOARDING_ANALYTICS_URL = gql`
  query employeeOnboardingAnalytics {
    employeeOnboardingAnalytics {
      activityUrl
    }
  }
`

export const GET_EXTERNAL_USERS = gql`
  query ExternalUsers($query: String, $pagination: PaginationInput) {
    externalUsers(query: $query, pagination: $pagination) {
      data {
        ...UserAutocompleteFields
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_EXTERNAL_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_USERS_WITH_ROLE_ASSIGNMENTS = gql`
  query Users(
    $query: String
    $pagination: PaginationInput
    $filterModel: FilterModelInput
  ) {
    users(query: $query, pagination: $pagination, filterModel: $filterModel) {
      data {
        ...UserFields
        isTerminated
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_USER_WITH_ASSIGNEES_FIELDS}
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_USERS = gql`
  query Users(
    $query: String
    $pagination: PaginationInput
    $filterModel: FilterModelInput
  ) {
    users(query: $query, pagination: $pagination, filterModel: $filterModel) {
      data {
        id
        email
        personalEmail
        firstName
        lastName
        fullName
        avatarUrl
        jobTitle
        startDate
        terminationDate
      }
      paginationMeta {
        ...PaginationMetaFields
      }
    }
  }
  ${FRAGMENT_PAGINATION_META_FIELDS}
`

export const GET_USER = gql`
  query User($id: ID!) {
    user(userId: $id) {
      id
      email
      personalEmail
      firstName
      lastName
    }
  }
`

export const GET_USER_TEMPLATE_ROLE_ASSIGNMENTS = gql`
  query User($id: ID!) {
    user(userId: $id) {
      userTemplateRolesAssignments {
        id
        templateRoleId
      }
    }
  }
`

export const GET_TEMPLATE_ROLES = gql`
  query TemplateRoles($showHidden: Boolean, $onlyAssignable: Boolean) {
    templateRoles(showHidden: $showHidden, onlyAssignable: $onlyAssignable) {
      id
      name
    }
  }
`

export const GET_TEMPLATE_ROLE_TEXTS = gql`
  query TemplateRoleTexts {
    templateRoleTexts {
      id
      templateRoleId
      text
      textType
    }
  }
`

export const ALL_CLIENT_CONFIGS = gql`
  query Configuration {
    configuration {
      clientLogo
      backgroundUrl
      welcomeIconUrl
      primaryColor
      fontColor
      iconColor
      cantCompleteAnnouncement
      categoryColors
    }
    clientSetting {
      sendSafely {
        url
        dropzoneId
      }
      availableLanguages {
        label
        value
      }
    }
    ${GET_CLIENT_CONFIG_STRING}
  }
`

export const USER_TASKS = gql`
  query UserTasks(
    $startDate: ISO8601Date!
    $locationHierarchyIds: [ID!]
    $searchTerm: String
  ) {
    userTasks(
      startDate: $startDate
      locationHierarchyIds: $locationHierarchyIds
      searchTerm: $searchTerm
    ) {
      id
      title
      description
      dueDate
      taskType
      mediaUrl
      important
      locationHierarchyId
      showNotApplicableOption
      frequencyType
      options {
        id
        option
      }
      allowMultiSelect
      taskCompleted
      lastEvent {
        eventType
      }
      section {
        id
        title
        owner {
          id
          fullName
        }
      }
      workflow {
        id
        title
        owner {
          id
          fullName
        }
      }
    }
  }
`

export const SCORM_COURSE_URL = gql`
  query ScormCourseUrl($taskId: ID!) {
    scormCourseUrl(taskId: $taskId)
  }
`

export const GET_TASKS = gql`
  query TasksFromLocations($locationHierarchyIds: [ID!]!) {
    tasksFromLocations(locationHierarchyIds: $locationHierarchyIds) {
      id
      title
      internalName
      taskType
      locationHierarchyId
      dueDate
      templateRoles {
        id
        name
      }
      lastEvent {
        eventType
        percentage
        score
        totalScore
      }
    }
  }
`

export const LOCATION_HIERARCHIES_GROUP = gql`
  query LocationHierarchiesGroup($userIds: [ID!]!) {
    locationHierarchiesGroup(userIds: $userIds)
  }
`

export const GET_MY_TEAM_TODO_TASKS = gql`
  query MyTeamToDoTasks($locationIds: [ID!]!, $userIds: [ID!]!) {
    myTeamToDoTasks(locationIds: $locationIds, userIds: $userIds)
  }
`
export const GET_TASK_FROM_TEMPLATE = gql`
  query GetTaskFromTemplate($attributes: GetTaskFromTemplateInput!) {
    getTaskFromTemplate(attributes: $attributes) {
      taskId
      locationHierarchyId
    }
  }
`

export const GET_TAXONOMY_TERM_HIERARCHY = gql`
  query TaxonomyTermHierarchy($taxonomyId: ID!) {
    taxonomyTermHierarchy(taxonomyId: $taxonomyId)
  }
`

export const GET_ACTIVE_ASSIGNMENTS = gql`
  query ActiveAssignments($userIds: [ID!]!, $roleId: ID!) {
    activeAssignments(userIds: $userIds, roleId: $roleId)
  }
`
